<template>
	<a-space direction="vertical">
		<div></div>
		<a-card class="home">
			欢迎 <b> {{ minfo.userName }} !</b>
		</a-card>

	</a-space>
</template>

<script>
	import { mapState } from 'vuex'
	export default {
		data() {
			return {};
		},
		computed: mapState(['minfo'])
	}
</script>